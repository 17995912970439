import React from 'react';
import { InputGroup, FormControl, Table, Button, Form, Col, Modal, FormGroup, Spinner } from 'react-bootstrap';

class Indicadores extends React.Component {
  handleOk = (item) => {
    //var item = this.
    console.log(item)
    this.props.infoModal(JSON.stringify(item));
  }
  handleChange = (e) => {
    // console.log(e);
    this.props.getIndicadoresByYear(e.target.value);
  }
  formatMonth = (value) => {
    let mes = this.props.meses?.find(e => e.value === value);
    return mes.text;
  }
  handleShowNotify = (msj) => {
    this.props.handleShowNotify(msj);
  }

  render() {

    return (
      <>
        <Table striped bordered hover variant="dark" className="mt-1">
          <thead>
            <tr>
              {this.props.cabs.map((value, index) => (
                <th key={index}>{value}</th>
              ))}
              <th colSpan="2">
                <InputGroup>
                  <FormControl as="select" placeholder="Año" defaultValue="0" size="sm" onChange={this.handleChange.bind(this)}>
                    <option value="0" disabled>--</option>
                    {
                      this.props.anyos.map((option, index) => {
                        return (<option key={index} value={option}>{option}</option>)
                      })
                    }
                  </FormControl>
                  <InputGroup.Append>
                    <Button variant="primary" size="sm" onClick={this.handleEditIndic.bind(this, {})}>Nuevo</Button>
                  </InputGroup.Append>
                </InputGroup>
              </th>
            </tr>
          </thead>
          <tbody>
            {this.props.indicadores?.sort((a, b) => a.wi_month - b.wi_month).map((indicador, i) => (
              <tr role="row" key={i}>
                <td key={i + 10}>{indicador.wi_year}</td>
                <td key={i + 20}>{this.formatMonth(indicador.wi_month)}</td>
                <td key={i + 30}>{indicador.wi_uf}</td>
                <td key={i + 40}>{indicador.wi_utm}</td>
                <td key={i + 50}><Button variant='info' onClick={this.handleEditIndic.bind(this, indicador)}>Editar</Button></td>
                <td key={i + 60}><Button variant='danger' onClick={this.handleElimIndic.bind(this.componentDidCatch, indicador)}><i class="fa fa-trash-o" aria-hidden="true"></i></Button></td>
              </tr>

            ))}
          </tbody>
        </Table>
        <Modal show={this.state.showEditIndicModal} onHide={this.handleCloseEditIndic}>
          <Modal.Header closeButton>
            <Modal.Title>Editar Indicador</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form>
              <Form.Row className="px-3">
                <Col>
                  <FormGroup>
                    <Form.Label>Mes</Form.Label>
                    <FormControl as="select" placeholder="Mes" defaultValue={this.state.wi_month} onChange={this.handleMonth.bind(this)}>
                      {
                        this.props.meses?.map((option, index) => {
                          return (<option key={index} value={option.value}>{option.text}</option>)
                        })
                      }
                    </FormControl>
                  </FormGroup>
                </Col>
                <Col>
                  <FormGroup>
                    <Form.Label>Año</Form.Label>
                    <FormControl as="select" placeholder="Año" defaultValue={this.state.wi_year} onChange={this.handleYear.bind(this)}>
                      {
                        this.props.anyos?.map((option, index) => {
                          return (<option key={index} value={option}>{option}</option>)
                        })
                      }
                    </FormControl>
                  </FormGroup>
                </Col>
              </Form.Row>
              <Form.Row className="px-3">
                <Col>
                  <FormGroup>
                    <Form.Label>UF</Form.Label>
                    <FormControl type="number" placeholder="UF" value={this.state.wi_uf} onChange={this.handleUf.bind(this)}>
                    </FormControl>
                  </FormGroup>
                </Col>
                <Col>
                  <FormGroup>
                    <Form.Label>UTM</Form.Label>
                    <FormControl type="number" placeholder="UTM" value={this.state.wi_utm} onChange={this.handleUtm.bind(this)}>
                    </FormControl>
                  </FormGroup>
                </Col>
              </Form.Row>
            </Form>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={this.handleCloseEditIndic}>
              Cancelar
            </Button>
            <Button variant="success" onClick={this.saveIndicador}>
            {this.state.showLoading && <Spinner as="span" animation="border"size='sm' />}{' '}
              Guardar
            </Button>
          </Modal.Footer>
        </Modal>
        <Modal show={this.state.showElimIndicModal} size="sm" onHide={this.handleCloseElimIndic}>
          <Modal.Header closeButton>
            <Modal.Title>
              Eliminación
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
          ¿Está seguro que desea eliminar este registro [{this.state.wi_month}/{this.state.wi_year}]?
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={this.handleCloseElimIndic}>
              Cancelar
            </Button>
            <Button variant="danger" onClick={this.elimIndicador}>
              Eliminar
            </Button>
          </Modal.Footer>
        </Modal>
      </>
    )
  }
  state = {
    baseUrl: "https://us-central1-misueldoapi.cloudfunctions.net",
    showEditIndicModal: false,
    wi_month: '',
    wi_year: '',
    wi_uf: '',
    wi_utm: '',
    msj: '',
    showNotify: false,
    showElimIndicModal: false,
    showLoading: false,
  };
  handleEditIndic = (item) => {
    // console.log(item);
    this.infoModal(item);
    this.setState({ showEditIndicModal: true });
  };
  handleCloseEditIndic = (e) => {
    this.setState({ showEditIndicModal: false });
  };
  infoModal = (item) => {
    var data = item;
    this.setState({ wi_year: data.wi_year });
    this.setState({ wi_month: data.wi_month });
    this.setState({ wi_uf: data.wi_uf });
    this.setState({ wi_utm: data.wi_utm });
    // this.setState({ wi_obs: data.wi_obs });
    // console.log(data.wi_uf);
  };
  handleUf = (event) => {
    this.setState({ wi_uf: event.target.value });
  };
  handleUtm = (event) => {
    this.setState({ wi_utm: event.target.value });
  };
  handleYear = (event) => {
    this.setState({ wi_year: event.target.value });
  };
  handleMonth = (event) => {
    this.setState({ wi_month: event.target.value });
  };
  handleElimIndic = (item) => {
    this.infoModal(item);
    this.setState({ showElimIndicModal: true });
  }
  handleCloseElimIndic = (e) => {
    this.setState({ showElimIndicModal: false });
  };
  saveIndicador = (event) => {
    event.preventDefault();
    this.setState({ showLoading: true });
    var data = {
      year: this.state.wi_year,
      month: this.state.wi_month,
      uf: this.state.wi_uf,
      utm: this.state.wi_utm,
      obs: ""
    }
    console.log(data);
    fetch(this.state.baseUrl + "/guardar", {
      method: 'post',
      headers: {
        'Accept': 'application/json',
        "Content-type": "application/x-www-form-urlencoded; charset=UTF-8"
      }, body: JSON.stringify(data)//, mode: 'no-cors'
    }).then(res => res.json()).then((response) => {
      this.handleShowNotify(response.message);
      // this.setState({ showNotify: true });
      console.log(response);
      this.setState({ showEditIndicModal: false });
      this.setState({ showLoading: true });
    }).catch((error) => {
      // this.setState({ showNotify: true });
      this.handleShowNotify(error);
      this.setState({ showLoading: false });
      console.log(error);
    });
  };
  handleHideNotify = (event) => {
    this.setState({ showNotify: false })
  }
  // handleShowNotify = (msj) => {
  //   this.setState({ showNotify: true });
  //   this.setState({ msj: msj });
  // };
  // handleHideNotify = () => {
  //   this.setState({ showNotify: false })
  // }

}

export default Indicadores