import React from 'react';
import { FormControl, Table, Button, Form, Col, Modal, FormGroup, Spinner } from 'react-bootstrap';

class Params extends React.Component {
  handleOk = (item) => {
    //var item = this.
    console.log(item)
    this.props.infoModal(JSON.stringify(item));
  }
  handleChange = (e) => {
    // console.log(e);
    this.props.getIndicadoresByYear(e.target.value);
  }
  formatMonth = (value) => {
    let mes = this.props.meses?.find(e => e.value === value);
    return mes.text;
  }
  handleShowNotify = (msj) => {
    this.props.handleShowNotify(msj);
  }

  render() {

    return (
      <>
        <Table striped bordered hover variant="dark" className="mt-1">
          <thead>
            <tr>
              {this.state.cabs?.map((value, index) => (
                <th key={index}>{value}</th>
              ))}
              <th colSpan="2">
              </th>
            </tr>
          </thead>
          <tbody>
            {this.props.params?.map((param, i) => (
              <tr role="row" key={i}>
                <td key={i + 10}>{param.wp_minimo}</td>
                <td key={i + 20}>{param.wp_horassemanales}</td>
                <td key={i + 30}>{param.wp_topeAfp}UF</td>
                <td key={i + 40}>{param.wp_cesantia}%</td>
                <td key={i + 50}>{param.wp_salud}%</td>
                <td key={i + 60}>{param.wp_topeCesantia}UF</td>
                <td key={i + 70}><Button variant='info' onClick={this.handleEditIndic.bind(this, param)}>Editar</Button></td>
                {/* <td key={i + 80}><Button variant='danger' onClick={this.handleElimIndic.bind(this.componentDidCatch, param)}>&#10007;</Button></td> */}
              </tr>

            ))}
          </tbody>
        </Table>
        <Modal show={this.state.showEditIndicModal} onHide={this.handleCloseEditIndic}>
          <Modal.Header closeButton>
            <Modal.Title>Editar Parametro</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form>
              <Form.Row className="px-3">
                <Col>
                  <FormGroup>
                    <Form.Label>Sueldo Minimo</Form.Label>
                    <FormControl type='number' placeholder='Sueldo Minimo' value={this.state.wp_minimo} onChange={this.handleSueldoMinimo.bind(this)}>
                    </FormControl>
                  </FormGroup>
                </Col>
                <Col>
                  <FormGroup>
                    <Form.Label>Horas Semanales</Form.Label>
                    <FormControl type='number' placeholder="Horas Semanales" value={this.state.wp_horassemanales} onChange={this.handleHorasSemanales.bind(this)}>
                    </FormControl>
                  </FormGroup>
                </Col>
              </Form.Row>
              <Form.Row className="px-3">
                <Col>
                  <FormGroup>
                    <Form.Label>Tope AFP</Form.Label>
                    <FormControl type="number" placeholder="Tope AFP" value={this.state.wp_topeAfp} onChange={this.handleTopeAfp.bind(this)}>
                    </FormControl>
                  </FormGroup>
                </Col>
                <Col>
                  <FormGroup>
                    <Form.Label>Seguro de Cesantia</Form.Label>
                    <FormControl type="number" placeholder="Seguro de Cesantia" value={this.state.wp_cesantia} onChange={this.handleCesantia.bind(this)}>
                    </FormControl>
                  </FormGroup>
                </Col>
              </Form.Row>
              <Form.Row className="px-3">
                <Col>
                  <FormGroup>
                    <Form.Label>Cotizacion de Salud</Form.Label>
                    <FormControl type="number" placeholder="Cotizacion de Salud" value={this.state.wp_salud} onChange={this.handleSalud.bind(this)}>
                    </FormControl>
                  </FormGroup>
                </Col>
                <Col>
                  <FormGroup>
                    <Form.Label>Tope Seguro de Cesantia</Form.Label>
                    <FormControl type="number" placeholder="Tope Seguro de Cesantia" value={this.state.wp_topeCesantia} onChange={this.handleTopeCesantia.bind(this)}>
                    </FormControl>
                  </FormGroup>
                </Col>
              </Form.Row>
            </Form>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={this.handleCloseEditIndic}>
              Cancelar
            </Button>
            <Button variant="success" onClick={this.saveParam}>
              {this.state.showLoading && <Spinner as="span" animation="border" size='sm' />}{' '}
              Guardar
            </Button>
          </Modal.Footer>
        </Modal>
        <Modal show={this.state.showElimIndicModal} size="sm" onHide={this.handleCloseElimIndic}>
          <Modal.Header closeButton>
            <Modal.Title>
              Eliminación
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            ¿Está seguro que desea eliminar este registro [{this.state.wi_month}/{this.state.wi_year}]?
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={this.handleCloseElimIndic}>
              Cancelar
            </Button>
            <Button variant="danger" onClick={this.elimIndicador}>
              Eliminar
            </Button>
          </Modal.Footer>
        </Modal>
      </>
    )
  }
  state = {
    cabs: ["Sueldo Minimo", "Horas Semanales", "Tope AFP", "Seguro de Cesantia", "Cotizacion de Salud", "Tope Seguro de Cesantia"],
    baseUrl: "https://us-central1-misueldoapi.cloudfunctions.net",
    showEditIndicModal: false,
    wp_minimo: '',
    wp_horassemanales: '',
    wp_topeAfp: '',
    wp_cesantia: '',
    wp_salud: '',
    wp_topeCesantia: '',
    msj: '',
    showNotify: false,
    showElimIndicModal: false,

  };
  handleEditIndic = (item) => {
    // console.log(item);
    this.infoModal(item);
    this.setState({ showEditIndicModal: true });
  };
  handleCloseEditIndic = (e) => {
    this.setState({ showEditIndicModal: false });
  };
  infoModal = (item) => {
    var data = item;
    this.setState({ wp_minimo: data.wp_minimo });
    this.setState({ wp_horassemanales: data.wp_horassemanales });
    this.setState({ wp_topeAfp: data.wp_topeAfp });
    this.setState({ wp_cesantia: data.wp_cesantia });
    this.setState({ wp_salud: data.wp_salud });
    this.setState({ wp_topeCesantia: data.wp_topeCesantia });
    // console.log(data.wi_uf);
  };
  handleSueldoMinimo = (event) => {
    this.setState({ wp_minimo: event.target.value });
  };
  handleHorasSemanales = (event) => {
    this.setState({ wp_horassemanales: event.target.value });
  };
  handleTopeAfp = (event) => {
    this.setState({ wp_topeAfp: event.target.value });
  };
  handleCesantia = (event) => {
    this.setState({ wp_cesantia: event.target.value });
  };
  handleSalud = (event) => {
    this.setState({ wp_salud: event.target.value });
  };
  handleTopeCesantia = (event) => {
    this.setState({ wp_topeCesantia: event.target.value });
  };
  handleElimIndic = (item) => {
    this.infoModal(item);
    this.setState({ showElimIndicModal: true });
  }
  handleCloseElimIndic = (e) => {
    this.setState({ showElimIndicModal: false });
  };
  saveParam = (event) => {
    event.preventDefault();
    this.setState({ showLoading: true });
    var data = {
      minimo: this.state.wp_minimo,
      horassemanales: this.state.wp_horassemanales,
      cesantia: this.state.wp_cesantia,
      topeafp: this.state.wp_topeAfp,
      salud: this.state.wp_salud,
      topecesantia: this.state.wp_topeCesantia
    }
    console.log(data);
    fetch(this.state.baseUrl + "/guardarParam", {
      method: 'post',
      headers: {
        'Accept': 'application/json',
        "Content-type": "application/x-www-form-urlencoded; charset=UTF-8"
      },
      body: JSON.stringify(data)//, mode: 'no-cors'
    }).then(res => res.json()).then((response) => {
      this.handleShowNotify(response.message);
      // this.setState({ showNotify: true });
      console.log(response);
      this.setState({ showEditIndicModal: false });
      this.setState({ showLoading: false });
      this.props.getParams();
    }).catch((error) => {
      // this.setState({ showNotify: true });
      this.handleShowNotify(error);
      console.log(error);
      this.setState({ showLoading: false });
    });
  };
  handleHideNotify = (event) => {
    this.setState({ showNotify: false })
  }

}

export default Params