import React from "react";
import Indicadores from './components/indicadores';
import Afps from './components/afps';
import Params from './components/params';
import Tramos from './components/tramos';
import AsignFam from './components/asignfam';
import { Container, Row, Col, InputGroup, FormControl, Tabs, Tab, Form, Alert, Spinner, Button } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap';
import Cookies from "universal-cookie";

const cookies = new Cookies();

class App extends React.Component {
  formatMonth = (value) => {
    let mes = this.state.meses.find(e => e.value === value);
    return mes.text;
  }
  handleChange = (e) => {
    this.getIndicadoresByYear(e.target.value);
  }
  handleServer = (e) => {
    this.setState({baseUrl: e.target.value});
  }
  handleLogout = (e) => {
    cookies.remove('isAuthenticated');
    window.location.href = '/';
  }

  render() {
    return (
      <>
        <div style={{ width: '100%', display: 'flex' }} className="justify-content-center">
          <div style={{ width: '300px' }}>
            <Alert show={this.state.showNotify} delay={3000} dismissible onClose={this.handleHideNotify} variant={this.state.notifyVariant}>
              <b>{this.state.msj}</b>
            </Alert></div></div>
        <Container>
          <Row className="justify-content-center">
            <Col className="col-4">
            </Col>
            <Col className="col-4">
              <h1 className="display-4 text-center">{this.props.msg}</h1>
            </Col>
            <Col className="col-4">
              <Row className="d-flex align-items-center">
                <Col className="col-6">
                  Dark mode
                </Col>
                <Col className="col-6 justify-content-end">
                  <div className="py-2 px-2 text-center">
                    <Button type="button" variant="secondary" size="sm" onClick={this.handleLogout.bind(this)}>Salir</Button>
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
          <Row className="justify-content-center">
            <Col className="col-md-auto ">
              <Form>
                <InputGroup className="mb-3">
                  <InputGroup.Prepend>
                    <InputGroup.Text id="basic-addon1">Servidor:</InputGroup.Text>
                  </InputGroup.Prepend>
                  <FormControl as="select" placeholder="Type" onChange={this.handleServer.bind(this)}>
                    {
                      this.state.servers.map((option, index) => {
                        return (<option key={index} value={option}>{option}</option>)
                      })
                    }
                  </FormControl>
                </InputGroup>
              </Form>
            </Col>
          </Row>
          <Row>
            <Col>
              <Tabs defaultActiveKey="indic" id="tabs-home" className="justify-content-center " onSelect={this.handleTab}>
                <Tab eventKey="indic" title="Indicadores">
                  <Row className="justify-content-center">
                    <Col className="col-auto">
                      {this.state.showLoading && <div className="text-center mt-1"><Spinner as="span" animation="border" /></div>}
                      <Indicadores indicadores={this.state.indicadores} meses={this.state.meses} cabs={this.state.cabs} anyos={this.state.anyos} infoModal={this.state.infoModal} getIndicadoresByYear={this.getIndicadoresByYear} handleShowNotify={this.handleShowNotify} />
                    </Col>
                  </Row>
                </Tab>
                <Tab eventKey="afps" title="Afps">
                  <Row className="justify-content-center">
                    <Col className="col-auto">
                      {this.state.showLoading && <div className="text-center mt-1"><Spinner as="span" animation="border" /></div>}
                      <Afps afps={this.state.afps} getAfps={this.getAfps}  handleShowNotify={this.handleShowNotify} />
                    </Col>
                  </Row>
                </Tab>
                <Tab eventKey="params" title="Parametros">
                  <Row className="justify-content-center">
                    <Col className="col-auto">
                      {this.state.showLoading && <div className="text-center mt-1"><Spinner as="span" animation="border" /></div>}
                      <Params params={this.state.params} getParams={this.getParams} infoModal={this.state.infoModal} handleShowNotify={this.handleShowNotify}/>
                    </Col>
                  </Row>
                </Tab>
                <Tab eventKey="tramos" title="Tramos">
                  <Row className="justify-content-center">
                    <Col className="col-auto">
                      {this.state.showLoading && <div className="text-center mt-1"><Spinner as="span" animation="border" /></div>}
                      <Tramos tramos={this.state.tramos} getTramos={this.getTramos} />
                    </Col>
                  </Row>
                </Tab>
                <Tab eventKey="asignfam" title="Asign. Fam.">
                  <Row className="justify-content-center">
                    <Col className="col-auto">
                      {this.state.showLoading && <div className="text-center mt-1"><Spinner as="span" animation="border" /></div>}
                      <AsignFam asignfam={this.state.asignfam} getAsignFam={this.getAsignFam} />
                    </Col>
                  </Row>
                </Tab>
              </Tabs>
            </Col>
          </Row>
        </Container>
      </>
    )
  }
  state = {
    servers: [
      "https://sa7qcspu9l.execute-api.us-east-1.amazonaws.com/api",
      "https://k7labs.ml/rest",
      "https://us-central1-misueldoapi.cloudfunctions.net"
    ],
    cabs: ["Año", "Mes", "UF", "UTM"],
    indicadores: [],
    afps: [],
    params: [],
    tramos: [],
    asignfam: [],
    meses: [
      { value: 1, text: "enero" },
      { value: 2, text: "febrero" },
      { value: 3, text: "marzo" },
      { value: 4, text: "abril" },
      { value: 5, text: "mayo" },
      { value: 6, text: "junio" },
      { value: 7, text: "julio" },
      { value: 8, text: "agosto" },
      { value: 9, text: "septiembre" },
      { value: 10, text: "octubre" },
      { value: 11, text: "noviembre" },
      { value: 12, text: "diciembre" }
    ],
    anyos: [2023, 2022, 2021, 2020, 2019, 2018],
    baseUrl: "https://us-central1-misueldoapi.cloudfunctions.net",
    showEditIndicModal: false,
    wi_month: '',
    wi_year: '',
    wi_uf: '',
    wi_utm: '',
    msj: '',
    showNotify: false,
    showLoading: false,
    notifyVariant: 'success',
  };
  componentDidMount() {
    // var hoy = new Date();
    // this.console.log(hoy);
    // var anyo = hoy.getFullYear();
    // this.getAfps();
    // this.getIndicadoresByYear(anyo);
  };
  handleTab = (tab) => {
    switch (tab) {
      case 'indic':
        var hoy = new Date();
        var anyo = hoy.getFullYear();
        this.getIndicadoresByYear(anyo);
        break;
      case 'afps':
        this.setState({afps: []});
        this.getAfps();
        break;
      case 'params':
        this.getParams();
        break;
      case 'tramos':
        this.getTramos();
        break;
      case 'asignfam':
        this.getAsignFam();
        break;
      default:
        break;
    }
    // console.log(tab);
  };
  getIndicadoresByYear = (anyo) => {
    this.setState({ showLoading: true });
    fetch(this.state.baseUrl + '/indicadores/' + anyo).then(res => res.json()).then((data) => {
      this.setState({ indicadores: data.indicadores[0] });
      console.log(this.state.indicadores);
      this.setState({ showLoading: false });
    }).catch((error) => {
      this.setState({ showLoading: false });
      console.log(error);
    });
  };
  getAfps = () => {
    this.setState({ showLoading: true });
    fetch(this.state.baseUrl + '/afps').then(res => res.json()).then((data) => {
      this.setState({ afps: data.afp });
      // console.log(this.state.afps);
      this.setState({ showLoading: false });
    }).catch((error) => {
      this.setState({ showLoading: false });
      this.handleShowNotify(JSON.stringify(error), 'danger');
      // console.log(error);
    });
  };
  getParams = () => {
    this.setState({ showLoading: true });
    fetch(this.state.baseUrl + '/params').then(res => res.json()).then((data) => {
      this.setState({ params: data.params });
      // console.log(this.state.afps);
      this.setState({ showLoading: false });
    }).catch(console.log)
  };
  getTramos = () => {
    this.setState({ showLoading: true });
    fetch(this.state.baseUrl + '/tramos').then(res => res.json()).then((data) => {
      this.setState({ tramos: data.tramos });
      // console.log(this.state.afps);
      this.setState({ showLoading: false });
    }).catch(console.log)
  };
  getAsignFam = () => {
    this.setState({ showLoading: true });
    fetch(this.state.baseUrl + '/asignfam').then(res => res.json()).then((data) => {
      this.setState({ asignfam: data.asignfam });
      // console.log(this.state.afps);
      this.setState({ showLoading: false });
    }).catch(console.log)
  };
  getUrl = (url) => {
    this.setState({ baseUrl: url });
  };
  handleEditIndic = (item) => {
    // console.log(item);
    this.infoModal(item);
    this.setState({ showEditIndicModal: true });
  };
  handleCloseEditIndic = (e) => {
    this.setState({ showEditIndicModal: false });
  };
  infoModal = (item) => {
    var data = item;
    this.setState({ wi_year: data.wi_year });
    this.setState({ wi_month: data.wi_month });
    this.setState({ wi_uf: data.wi_uf });
    this.setState({ wi_utm: data.wi_utm });
    // this.setState({ wi_obs: data.wi_obs });
    // console.log(data.wi_uf);
  };
  handleUf = (event) => {
    this.setState({ wi_uf: event.target.value });
  };
  handleUtm = (event) => {
    this.setState({ wi_utm: event.target.value });
  };
  handleYear = (event) => {
    this.setState({ wi_year: event.target.value });
  };
  handleMonth = (event) => {
    this.setState({ wi_month: event.target.value });
  };
  saveIndicador = (event) => {
    event.preventDefault();
    var data = {
      year: this.state.wi_year,
      month: this.state.wi_month,
      uf: this.state.wi_uf,
      utm: this.state.wi_utm,
      obs: ""
    }
    console.log(data);
    fetch(this.state.baseUrl + "/guardar", {
      method: 'post',
      headers: {
        'Accept': 'application/json',
        "Content-type": "application/x-www-form-urlencoded; charset=UTF-8"
      }, body: JSON.stringify(data)//, mode: 'no-cors'
    }).then(res => res.json()).then((response) => {
      this.setState({ msj: response.message });
      this.setState({ showNotify: true });
      console.log(response);
      this.setState({ showEditIndicModal: false });
    }).catch((error) => {
      this.setState({ showNotify: true });
      console.log(error);
    });
  };
  handleShowNotify = (msj, variant) => {
    this.setState({ showNotify: true });
    this.setState({ notifyVariant: variant });
    this.setState({ msj: msj });
  };
  handleHideNotify = () => {
    this.setState({ showNotify: false })
  }
}
export default App;