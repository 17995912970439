import React from "react";
import { Form, Row, Col, Container, Button, Alert } from "react-bootstrap";
import Cookies from "universal-cookie";
// import PropTypes from "prop-types";

const cookies = new Cookies();

// const navigate = useNavigate;
class Login extends React.Component {
  // static propTypes = {
  //   match: PropTypes.object.isRequired,
  //   location: PropTypes.object.isRequired,
  //   history: PropTypes.object.isRequired
  // };
  handleLogin = (event) => {
    const f = event.currentTarget;
    if (f.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    }
    else {
      this.setState({ showLoading: true });
      var data = {
        user: this.state.user,
        pass: this.state.pass,
      }
      console.log(data);
      fetch(this.state.baseUrl + "/login", {
        method: 'post',
        headers: {
          'Accept': 'application/json',
          "Content-type": "application/x-www-form-urlencoded; charset=UTF-8"
        },
        body: JSON.stringify(data)//, mode: 'no-cors'
      }).then(res => res.json()).then((response) => {
        // this.handleShowNotify(response.message);
        // this.setState({ showNotify: true });
        // console.log(response);
        // this.setState({ showEditIndicModal: false });
        // this.setState({ showLoading: true });
        if (response.LoginStatus === 1) {
          // navigate("/");
          // history.push('/');
          var hoy = new Date();
          var venc = new Date(hoy.getFullYear(), hoy.getMonth() + 1, hoy.getDate());
          cookies.set('isAuthenticated', response.LoginStatus === 1, { expires: venc })
          window.location.href = '/';
        }
        else {
          this.handleShowNotify('¡Usuario y/o contraseña incorrecta!','danger');
          console.log('Error: usuario y/o contraseña incorrecta.');
        }
      }).catch((error) => {
        // this.setState({ showNotify: true });
        // this.handleShowNotify(error);
        // this.setState({ showLoading: false });
        console.log(error);
      });
    }
    event.preventDefault();
    this.setState({ validated: true });
  }
  handleUser = (e) => {
    this.setState({ user: e.target.value });
  }
  handlePass = (e) => {
    this.setState({ pass: e.target.value });
  }
  render() {
    // const { match, location, history } = this.props;
    return (
      <>
        <div style={{ width: '100%', display: 'flex' }} className="justify-content-center">
          <div style={{ width: '400px' }}>
            <Alert className="text-center" show={this.state.showNotify} delay={3000} dismissible onClose={this.handleHideNotify} variant={this.state.notifyVariant}>
              <b>{this.state.msj}</b>
            </Alert></div></div>
        <Container>
          <div>
            <h1 className="display-4 text-center">{this.props.msg}</h1>
          </div>
          <Row className="justify-content-center">
            <Col className="col-md-auto">
              <h3 className="display-5">Inicio de Sesión</h3>
              <Form noValidate validated={this.state.validated} onSubmit={this.handleLogin.bind(this)}>
                <Form.Group>
                  <Form.Label>Usuario</Form.Label>
                  <Form.Control as="input" type="text" placeholder="Usuario" id="user" onChange={this.handleUser.bind(this)} required />
                  <Form.Control.Feedback type="invalid">El usuario es obligatorio.</Form.Control.Feedback>
                </Form.Group>
                <Form.Group>
                  <Form.Label>Contraseña</Form.Label>
                  <Form.Control as="input" type="password" placeholder="Contraseña" id="pass" onChange={this.handlePass.bind(this)} required />
                  <Form.Control.Feedback type="invalid">Debes ingresar una contraseña.</Form.Control.Feedback>
                </Form.Group>
                <Button type="submit" variant="primary" size="lg" block>Entrar</Button>
              </Form>
            </Col>
          </Row>
        </Container>
      </>
    )
  }
  state = {
    baseUrl: "https://us-central1-misueldoapi.cloudfunctions.net",
    user: '',
    pass: '',
    validated: false,
    msj: '',
    showNotify: false,
    showLoading: false,
    notifyVariant: 'success',

  }
  handleShowNotify = (msj, variant) => {
    this.setState({ showNotify: true });
    this.setState({ notifyVariant: variant });
    this.setState({ msj: msj });
  };
  handleHideNotify = () => {
    this.setState({ showNotify: false })
  }

}

export default Login;