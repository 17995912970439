import React from 'react';
import ReactDOM from 'react-dom';
import { Switch, BrowserRouter, Route } from "react-router-dom";
import './index.css';
import App from './App';
import Login from './components/login';
import * as serviceWorker from './serviceWorker';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap';
import Cookies from 'universal-cookie';

const cookies = new Cookies();

export default function Home() {
    var isAuth = cookies.get('isAuthenticated');
    if (!isAuth) {
        return <Login msg="data.manager" />
    }
    return (
        <BrowserRouter>
            <Switch>
                <Route exact path="/"><App msg="data.manager" /></Route>
                <Route path="/login"><Login msg="data.manager" /></Route>
            </Switch>
        </BrowserRouter>
    );
}

ReactDOM.render(<Home />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
