import React from 'react';
import { Table, Button, Form, Col, FormControl, Modal, FormGroup, Spinner } from 'react-bootstrap';

class Afps extends React.Component {
  handleOk = (item) => {
    //var item = this.
    console.log(item)
    this.props.infoModal(JSON.stringify(item));
  }
  handleChange = () => {
    // console.log(e);
    this.props.getAfps();
  }
  formatMonth = (value) => {
    let mes = this.props.meses.find(e => e.value === value);
    return mes.text;
  }

  render() {

    return (
      <>
        <Table striped bordered hover variant="dark" className="mt-1">
          <thead>
            <tr>
              {this.state.cabs.map((value, index) => (
                <th key={index}>{value}</th>
              ))}
              <th colSpan="2" className="text-center">
                <Button variant="primary" size="sm" onClick={this.handleEditAfp.bind(this, {})}>Nueva Afp</Button>
              </th>
            </tr>
          </thead>
          <tbody>
            {this.props.afps?.sort((a, b) => a.Id - b.Id).map((afp, i) => (
              <tr role="row" key={i}>
                <td key={i + 100}>{afp.wa_nombre}</td>
                <td key={i + 300}>{afp.wa_porcentaje}%</td>
                <td key={i + 500}><Button variant='info' onClick={this.handleEditAfp.bind(this, afp)}>Editar</Button></td>
                <td key={i + 600}><Button variant='danger' onClick={this.handleElimAfp.bind(this, afp)}><i className="fa fa-trash-o" aria-hidden="true"></i></Button></td>
              </tr>

            ))}
          </tbody>
        </Table>
        <Modal show={this.state.showEditAfpModal} onHide={this.handleCloseEditAfp}>
          <Modal.Header closeButton>
            <Modal.Title>Editar Afp</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form>
              <Form.Row className="px-3">
                <Col>
                  <FormGroup>
                    <Form.Label>Nombre</Form.Label>
                    <FormControl type="text" placeholder="Nombre" value={this.state.wa_nombre} onChange={this.handleNombreAfp.bind(this)}>
                    </FormControl>
                  </FormGroup>
                </Col>
                <Col>
                  <FormGroup>
                    <Form.Label>Porcentaje</Form.Label>
                    <FormControl type="number" placeholder="Porcentaje" value={this.state.wa_porcentaje} onChange={this.handlePorcentaje.bind(this)}>
                    </FormControl>
                  </FormGroup>
                </Col>
              </Form.Row>
            </Form>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={this.handleCloseEditAfp}>
              Cancelar
            </Button>
            <Button variant="success" onClick={this.saveAfp}>
              {this.state.showLoading && <Spinner as="span" size="sm" animation="border" />}{' '}
              Guardar
            </Button>
          </Modal.Footer>
        </Modal>
        <Modal show={this.state.showElimAfpModal} size="sm" onHide={this.handleCloseElimAfp}>
          <Modal.Header closeButton>
            <Modal.Title>
              Eliminación
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            ¿Está seguro que desea eliminar este registro [{this.state.wa_nombre}]?
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={this.handleCloseElimAfp}>
              Cancelar
            </Button>
            <Button variant="danger" onClick={this.elimIndicador}>
              Eliminar
            </Button>
          </Modal.Footer>
        </Modal>

      </>
    )
  }
  state = {
    baseUrl: "https://us-central1-misueldoapi.cloudfunctions.net",
    cabs: ["Nombre", "Porcentaje"],
    showEditAfpModal: false,
    wa_nombre: '',
    wa_porcentaje: '',
    Id: '',
    showElimAfpModal: false,
    showNotify: false,
    msj: '',
    showLoading: false,
  }
  componentDidMount() {
    this.props.getAfps();
  }
  handleNombreAfp = (event) => {
    this.setState({ wa_nombre: event.target.value });
  };
  handlePorcentaje = (event) => {
    this.setState({ wa_porcentaje: event.target.value });
  };
  infoModal = (item) => {
    var data = item;
    this.setState({ wa_nombre: data.wa_nombre });
    this.setState({ wa_porcentaje: data.wa_porcentaje });
    this.setState({ Id: data.Id });
    // console.log(data.wi_uf);
  };
  handleEditAfp = (item) => {
    // console.log(item);
    this.infoModal(item);
    this.setState({ showEditAfpModal: true });
  };
  handleCloseEditAfp = (e) => {
    this.setState({ showEditAfpModal: false });
  };
  handleElimAfp = (item) => {
    this.infoModal(item);
    this.setState({ showElimAfpModal: true });
  }
  handleCloseElimAfp = (e) => {
    this.setState({ showElimAfpModal: false });
  };
  saveAfp = (event) => {
    event.preventDefault();
    this.setState({ showLoading: true });
    var data = {
      nombre: this.state.wa_nombre,
      porcentaje: this.state.wa_porcentaje,
      id: this.state.Id
    }
    console.log(data);
    fetch(this.state.baseUrl + "/guardarafp", {
      method: 'post',
      headers: {
        'Accept': 'application/json',
        "Content-type": "application/x-www-form-urlencoded; charset=UTF-8"
      },
      body: JSON.stringify(data)//, mode: 'no-cors'
    }).then(res => res.json()).then((response) => {
      this.handleShowNotify(response.message, 'success');
      // this.setState({ showNotify: true });
      console.log(response);
      this.setState({ showEditAfpModal: false });
      this.setState({ showLoading: false });
    }).catch((error) => {
      // this.setState({ showNotify: true });
      this.handleShowNotify(error, 'danger');
      this.setState({ showLoading: false });
      console.log(error);
    });
  };
  handleShowNotify = (msj, variant) => {
    // this.setState({ showNotify: true });
    // this.setState({ msj: msj });
    this.props.handleShowNotify(msj, variant);

  };
  handleHideNotify = () => {
    this.setState({ showNotify: false })
  }

}

export default Afps